export default {
  data () {
    return {
      dataObjectList: [],
      dataObjectSelectedList: [],
      dataObjectSelectedListClone: [],
      dataObjectSelected: {},
      dataObjectSelectedClone: {}
    }
  },
  methods: {
    setItemProperty (dataObject, property, value) {
      let dataObjectIndex = 0
      if (dataObject['id'] !== undefined) {
        dataObjectIndex = this.dataObjectList.findIndex(o => o['id'] === dataObject['id'])
      } else if (dataObject['uuid'] !== undefined) {
        dataObjectIndex = this.dataObjectList.findIndex(o => o['uuid'] === dataObject['uuid'])
      } else {
        dataObjectIndex = -1
      }
      if (dataObjectIndex !== -1) {
        const dataObject = this.dataObjectList[dataObjectIndex]
        dataObject[property] = value
        this.$set(this.dataObjectList, dataObjectIndex, dataObject)
      }
    },
    changeOperationStatus (dataObject, status) {
      this.setItemProperty(dataObject, 'operation_status', status)
    },
    updateRowData (callback) {
      if (typeof callback === 'function') {
        callback({...this.dataObjectSelected})
      }
    },
    removeRowDataByIds (ids) {
      ids = [ids].flat()
      this.dataObjectList = this.dataObjectList.filter(o => !ids.includes(o['id']))
    }
  }
}
